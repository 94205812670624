<template>
  <PageHeader image="header-6" withMobile :title="$lang('title')" :breadcrumbs="$lang('breadcr')" />
  <section class="pt-8 pb-9 bg-blue4">
    <div class="container">
      <div class="row mb-5">
        <div class="col-lg-8">
          <h2 class="fw-semibold text-green1 mb-4">
            {{ $lang("title1") }}
          </h2>
          <p class="mb-6">
            {{ $lang("text1") }}
          </p>
          <p class="mb-4 fw-bold text-blue2">
            {{ $lang("text2") }}
          </p>
          <div class="d-grid grid-1-cols grid-md-2-cols gap-2">
            <div>
              <p class="fw-bold mb-1">
                {{ $lang("text3") }}
              </p>
              <p class="mb-4">
                David Russell, 40 Lower Dominick <br />
                Street, Dublin 1, Ireland
              </p>
              <p class="fw-bold mb-1">
                {{ $lang("text4") }}
              </p>
              <p>david@ned.ie | info@ned.ie</p>
            </div>
            <div>
              <p class="fw-bold mb-1">
                {{ $lang("text5") }}
              </p>
              <p>
                Dublin Office, 21 Fitzwilliam Square, <br />
                Dublin 2, D02 RD28. <br />
                +353 57 8684800 <br />
                +353 (0) 761 104 800 <br />
                info@dataprotection.ie
              </p>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-12"><Accordion :data="$lang('items')" /></div>
      </div>
    </div>
  </section>
</template>

<script>
import PageHeader from "@/components/PageHeader";
import Accordion from "@/components/Accordion.vue";

export default {
  components: {
    PageHeader,
    Accordion,
  },
};
</script>

<style></style>
